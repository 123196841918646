import React, { useState, useEffect } from "react";
import { getUserlogs } from "../../../services/Dashboard";

const UserLogs = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserlogs();

        setData(response?.history);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  console.log(data);
  return (
    <div className="recall-dashboard">
      <div className="recall-new-dashboard-grid">
        <div className="user-logs">
          {data.map((item, index) => {
            return <div className="user-logs-item">{item.log}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

export default UserLogs;
