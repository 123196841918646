import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import DashboardIcons from "../../assets/DashboardIcons";
import { getAdminStats } from "../../services/Dashboard";
import "../../styles/dashboard.css";
const Dashboard = () => {
  const navigate = useNavigate();

  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAdminStats();
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  console.log(data);
  const recents = [
    {
      icon: DashboardIcons.AllUsers,
      number: data?.total_users,
      subtitle: "Total ",
      text: "All Users",
      path: "/all-users",
    },
    {
      icon: DashboardIcons.AllMail,
      number: data?.total_contact_requests,
      subtitle: "Total ",
      text: "All Mails",
      path: "/all-mails",
    },
    {
      icon: DashboardIcons.Cans,
      number: data?.total_cans_recycled.cans_recycled__sum,
      subtitle: "Total ",
      text: "Total Cans",
      path: "/admin-allusers",
    },
    {
      icon: DashboardIcons.AllPickup,
      number: data?.total_pickups,
      subtitle: "Total ",
      text: "All Pending",
      path: "/pending-pickups",
    },
  ];
  return (
    <div className="dashboard">
      <div className="new-dashboard-grid">
        <div className="recall-admin-stats">
          {recents.map((recent, index) => {
            return (
              <div
                onClick={() => navigate(recent.path)}
                key={index}
                className="recall-admin-stat"
              >
                <div className="recall-admin-stat-left">
                  <p>{recent.text}</p>
                  <div className="recall-admin-stat-left-stat">
                    <h1>
                      <CountUp
                        start={0}
                        end={recent.number}
                        duration={1.4}
                        separator=","
                      />
                    </h1>
                    <img src={DashboardIcons.Arrow} alt="" />
                  </div>
                </div>
                <div className="recall-admin-stat-right">
                  <img src={recent.icon} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
