import AllProducts from "./Products/AllProducts";
import AddProduct from "./Products/AddProduct";
import DeleteProduct from "./Products/DeleteProduct";
import EditProduct from "./Products/EditProduct";
import AllOrders from "./Orders/AllOrders";
import AllReturns from "./Orders/AllReturns";
import AllTransactions from "./Transactions/AllTransactions";
const Ecommerce = {
  AllProducts,
  AddProduct,
  DeleteProduct,
  EditProduct,
  AllOrders,
  AllReturns,
  AllTransactions,
};

export default Ecommerce;
