import AllPickups from "./Pickups/AllPickups";
import PendingPickups from "./Pickups/PendingPickups";
import UserLogs from "./Pickups/UserLogs";
import AllotDates from "./Dates/AllotDates";
import EditAllotDates from "./Dates/EditAllotDates";
const PickupsManagement = {
  AllPickups,
  PendingPickups,
  UserLogs,
  AllotDates,
  EditAllotDates,
};

export default PickupsManagement;
