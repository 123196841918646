import React, { useState, useEffect } from "react";
import moment from "moment"; 
import {  useNavigate } from "react-router-dom"; 
import { getAreas,addarea } from "../../../services/Pickups";

const AllowDates = () => { 
    const navigate = useNavigate();
    const user = localStorage.getItem("userData");
    const userObj = JSON.parse(user);
    const [zone, setZone] = useState("");
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, ] = useState(""); 
    const [data, setData] = useState();
    const fetchData = async () => {
        try {
            const response = await getAreas();
            setData(response);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [userObj]);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform validation
        if (new Date(dateFrom) >= new Date(dateTo)) {
            alert("Date from must be less than date to");
            return;
        }

        // Create payload object
        const payload = {
            zone,
            date_from: formatDate(dateFrom),
            // date_to: formatDate(dateTo),
        };

        try {
            const response = await addarea(payload);

            fetchData();
            console.log(response);
        } catch (error) {
            // Handle error
            console.error(error);
        }
    };
    return (
        <div className="admin-page-allow-dates">
             
            <div className="profile-page-user-info-cards | profile-page-user-info-cards-flex">
                <div className="profile-page-user-info-card">
                    <div className="profile-page-user-info-card-header">
                        <h2>Allot Details according to zone</h2>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="alott-dates-inputs">
                            <div className="input-authentication">
                                <p>Add Zone</p> 
                                <input
                                    type="text"
                                    value={zone}
                                    onChange={(e) => setZone(e.target.value)}
                                    placeholder="Enter Zone"
                                />
                            </div>
                            <div className="input-authentication">
                                <p>Date </p>
                                <input
                                    type="date"
                                    value={dateFrom}
                                    onChange={(e) =>
                                        setDateFrom(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="edit-button | allot-dates-button">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                <div className="profile-page-zone-cards |  profile-page-user-info-card">
                    <div className="profile-page-user-info-card-header">
                        <h2>Pickup Dates for July 📅</h2>
                    </div>
                    <div className="profile-page-zone-cards-render">
                        {data?.areas?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        navigate(
                                            `/admin-allotdates/${item.id}`
                                        );
                                    }}
                                    className="profile-page-zone-card"
                                    key={index}
                                >
                                    <div className="profile-page-zone-card-header">
                                        <h2>{item.area}</h2>
                                    </div>
                                    <div className="profile-page-zone-card-header-date">
                                        <p> 
                                            {moment(item.date).format(
                                                "Do MMMM YYYY"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllowDates;
