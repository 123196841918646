import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Auth/Login";
import Ecommerce from "../pages/Ecommerce";
import Staff from "../pages/Staff";
import PickupsManagement from "../pages/PickupsManagement";
import AllUsers from "../pages/User/AllUser";

const authService = {
  isAuthenticated: () => {
    const user = JSON.parse(localStorage.getItem("recallAdmin"));
    return user !== null;
  },
};

const PrivateRoute = ({ path, element, private: isPrivate }) => {
  const isAuthenticated = authService.isAuthenticated();

  if (isPrivate && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!isPrivate && isAuthenticated) {
    return <Navigate to="/" />;
  }

  return element;
};

const routes = [
  {
    path: "/login",
    exact: true,
    name: "Home",
    element: <Login />,
    private: false,
  },
  {
    path: "/",
    exact: true,
    name: "Home",
    element: <Dashboard />,
    private: true,
  },
  {
    path: "/all-users",
    exact: true,
    name: "All Users",
    element: <AllUsers />,
    private: true,
  },
  {
    path: "/add-product",
    exact: true,
    name: "Home",
    element: <Ecommerce.AddProduct />,
    private: true,
  },
  {
    path: "/all-products",
    exact: true,
    name: "Home",
    element: <Ecommerce.AllProducts />,
    private: true,
  },
  {
    path: "/edit-product",
    exact: true,
    name: "Home",
    element: <Ecommerce.EditProduct />,
    private: true,
  },
  {
    path: "/edit-product",
    exact: true,
    name: "Home",
    element: <Ecommerce.EditProduct />,
    private: true,
  },
  {
    path: "/delete-product",
    exact: true,
    name: "Home",
    element: <Ecommerce.DeleteProduct />,
    private: true,
  },
  {
    path: "/all-orders",
    exact: true,
    name: "Home",
    element: <Ecommerce.AllOrders />,
    private: true,
  },
  {
    path: "/all-returns",
    exact: true,
    name: "Home",
    element: <Ecommerce.AllReturns />,
    private: true,
  },
  {
    path: "/all-transactions",
    exact: true,
    name: "Home",
    element: <Ecommerce.AllTransactions />,
    private: true,
  },
  {
    path: "/create-staff",
    exact: true,
    name: "Home",
    element: <Staff.CreateStaff />,
    private: true,
  },
  {
    path: "/edit-staff",
    exact: true,
    name: "Home",
    element: <Staff.EditStaff />,
    private: true,
  },
  {
    path: "/allot-staff",
    exact: true,
    name: "Home",
    element: <Staff.AllotStaff />,
    private: true,
  },
  {
    path: "/all-pickups",
    exact: true,
    name: "Home",
    element: <PickupsManagement.AllPickups />,
    private: true,
  },
  {
    path: "/pending-pickups",
    exact: true,
    name: "Home",
    element: <PickupsManagement.PendingPickups />,
    private: true,
  },
  {
    path: "/user-activity",
    exact: true,
    name: "Home",
    element: <PickupsManagement.UserLogs />,
    private: true,
  },
  {
    path: "/pending-pickups",
    exact: true,
    name: "Home",
    element: <PickupsManagement.PendingPickups />,
    private: true,
  },
  {
    path: "/allot-dates",
    exact: true,
    name: "Home",
    element: <PickupsManagement.AllotDates />,
    private: true,
  },
  {
    path: "/allot-dates/:id",
    exact: true,
    name: "Home",
    element: <PickupsManagement.EditAllotDates />,
    private: true,
  },
  
];

export default function Navigation() {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <PrivateRoute
              path={route.path}
              element={route.element}
              private={route.private}
            />
          }
        />
      ))}
    </Routes>
  );
}
