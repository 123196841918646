import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Done from "../assets/done.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width: "500px",
  p: 4.4,
};
const StatusChange = ({ open, handleClose, text,handleStatusChange }) => {

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="pickup-confirmation">
            {/* <img src={Done} alt="" /> */}
            <p>{text}</p>
            <div className="confirm-button">
              <button
                onClick={() => {
                    handleStatusChange("completed");
                    handleClose();
                }}
              >Yes</button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default StatusChange;
