import AllotStaff from "./AllotStaff";
import EditStaff from "./EditStaff";
import CreateStaff from "./CreateStaff";
const Staff = {
  AllotStaff,
  EditStaff,
  CreateStaff,
};

export default Staff;
