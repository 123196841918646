import React, { useState, useEffect } from "react";
import { getAllUser, getUserlogs } from "../../services/Dashboard";
import { DataGrid } from "@mui/x-data-grid";
import DashboardIcons from "../../assets/DashboardIcons";
import UserRecentActivity from "../../modals/UserModals/UserRecentActivity";

const AllUsers = () => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      // flex: 1,
      width: 350,
      renderCell: (params) => {
        const fullName = params.value;
        const email = params.row.email;
        return (
          <div className="name-field">
            <div className="name-field-h1">{fullName}</div>
            <div className="name-field-p">{email}</div>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      // flex: 1,
      width: 250,
      valueFormatter: (params) =>
        new Date(params.value).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
    {
      field: "type_of",
      headerName: "Type Of",
      // flex: 1,
      width: 170,

      renderCell: (params) => {
        const value = params.value;
        const color = value === "individual" ? "#C8F7F1" : "#F9EDCE";
        const fontColor = value === "individual" ? "#4B9C93" : "#A67A46";
        return (
          <span
            style={{
              backgroundColor: color,
              padding: "8px 18px",
              borderRadius: "18px",
              fontWeight: "500",
              color: fontColor,
              fontSize: "18px",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            {value}
          </span>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      // flex: 1
      width: 190,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        const handleDelete = () => {
          const { id } = params.row;
          setData((prevData) => prevData.filter((row) => row.id !== id));
        };
        return (
          <div className="actions-flex">
            <img
              onClick={() => getUserLogs(params.row.id)}
              src={DashboardIcons.Logs}
              alt=""
            />

            <img onClick={handleDelete} src={DashboardIcons.Delete} alt="" />
          </div>
        );
      },
    },
  ];
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setopen] = useState(false);

  const handleRowSelection = (selection) => {
    console.log("de");
    setSelectedRowIds(selection.selectionModel);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllUser();
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  console.log(data);
  const [logs, setlogs] = useState();
  const getUserLogs = async (userId) => {
    console.log(userId);
    try {
      const response = await getUserlogs(userId);
      const userLogs = response?.history.filter((log) => log.user === userId);
      setlogs(userLogs);
      setopen(true);
    } catch (error) {
      console.error(error);
    }
  };
  console.log("parent", logs);

  return (
    <div className="recall-dashboard">
      {open && (
        <UserRecentActivity
          open={open}
          handleClose={() => setopen(false)}
          data={logs}
        />
      )}

      <div className="recall-new-dashboard-grid">
        <div
          className="datagrid-table"
          style={{ height: 400, width: "100%", margin: "2rem 0" }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            autoHeight
            getRowHeight={(params) => 100}
            checkboxSelection
            columnHeaderHeight={80}
            onSelectionModelChange={handleRowSelection}
            selectionModel={selectedRowIds}
          />
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
