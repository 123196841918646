import { errorToast } from "../../utils/toast";
import apiCall from "../apiCall";


export const login = async (navigate, formData) => {
  try {
    const data = await apiCall("/getAdminToken", "POST", formData);
    const userData = {
      admin_token: data?.token,
      email: formData.email,
      password: formData.password,
    };
    localStorage.setItem("recallAdmin", JSON.stringify(userData));
    navigate("/");
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};
