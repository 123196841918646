import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "../../../styles/dashboard.css";
import {
  getAllPickups,
  grantPickup,
  incrementUserPoints,
  updatePickup,
} from "../../../services/Pickups";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import StatusChange from "../../../modals/StatusModals/StatusChange";

function StatusCell(props) {
  const { value } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [status, setStatus] = useState(value);

  const handleStatusChange = async (newStatus) => {
    const payload = {
      pickup_status: newStatus,
      private_key: props.private_key,
      pickup_id: props.id,
    };
    try {
      const response = await updatePickup(payload);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    if (newStatus === "completed") {
      console.log(props.data);
      const payload2 = {
        private_key: props.private_key,
        cans_recycled: props.data.row.pickup_size,
        // cans_recycled: props.pi,
        total_points: props.data.row.pickup_size,
      };
      console.log(payload2);
      const response2 = await incrementUserPoints(payload2);
      console.log(response2);
    }
    setStatus(newStatus);
    setShowOptions(false);
  };

  const handleClick = () => {
    setShowOptions(!showOptions);
  };
  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };
  return (
    <div className="status-render" onClick={handleClick}>
      <StatusChange
        open={open}
        handleClose={handleClose}
        text="Are you sure you want to change the status to complete?"
        handleStatusChange={handleStatusChange}
      />
      <p
        style={{
          color:
            status === "completed"
              ? "#02C20F"
              : status === "in_progress"
              ? "#FFBF00"
              : status === "confirmed"
              ? "#008BF5"
              : status === "pending"
              ? "#FF000D"
              : "#FFC107",
          backgroundColor:
            status === "completed"
              ? "#E7F9F1"
              : status === "in_progress"
              ? "#FFF8E3"
              : status === "confirmed"
              ? "#EBF1F8"
              : status === "pending"
              ? "#FAEDED"
              : "#FFC107",
          borderStyle: "solid",
          borderWidth: "1px",
          bordeColor:
            status === "completed"
              ? "#02C20F"
              : status === "in_progress"
              ? "#FFBF00"
              : status === "confirmed"
              ? "#008BF5"
              : status === "pending"
              ? "#FF000D"
              : "#FFC107",
        }}
      >
        {status}
      </p>
      {showOptions && (
        <div className="change-status">
          <p>Change Status to</p>
          <div className="change-status-btns">
            <button
              className="confirmed-btn"
              onClick={() => handleStatusChange("confirmed")}
            >
              Confirmed
            </button>
            {/* <button
              className="completed-btn"
              onClick={() => {
                setopen(true);
              }}
            >
              Completed
            </button> */}

            {/* <button
              className="pending-btn"
              onClick={() => handleStatusChange("pending")}
            >
              Pending
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
}

function DateCell(props) {
  // const { value } = props;
  //
  const [showOptions, setShowOptions] = useState(false);
  const handleClick = () => {
    setShowOptions(!showOptions);
    console.log(props);
  };
  const PrevIcon = () => (
    <span className="custom-arrow">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.64293 0.31839C7.35191 0.0478099 6.88072 0.0478099 6.59045 0.31839L0.435961 6.05539C-0.14532 6.59724 -0.14532 7.47628 0.435961 8.01813L6.63502 13.7974C6.92305 14.0653 7.38837 14.0687 7.68013 13.8044C7.97859 13.5345 7.98144 13.0898 7.6882 12.8157L2.0145 7.52762C1.72349 7.25635 1.72349 6.81717 2.0145 6.5459L7.64293 1.29942C7.93394 1.02884 7.93394 0.58897 7.64293 0.31839Z"
          fill="#4B4B4B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.255 0.202912C13.964 -0.0676686 13.4928 -0.0676686 13.2025 0.202912L7.04802 5.93991C6.46674 6.48176 6.46674 7.3608 7.04802 7.90265L13.2471 13.682C13.5351 13.9498 14.0004 13.9532 14.2922 13.6889C14.5906 13.419 14.5935 12.9743 14.3003 12.7003L8.62656 7.41214C8.33555 7.14087 8.33555 6.70169 8.62656 6.43042L14.255 1.18394C14.546 0.913358 14.546 0.473492 14.255 0.202912Z"
          fill="#4B4B4B"
        />
      </svg>
    </span>
  );

  const PrevIcon2 = () => (
    <span className="custom-arrow">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.64305 0.202912C7.35204 -0.0676686 6.88085 -0.0676686 6.59057 0.202912L0.436083 5.93991C-0.145198 6.48176 -0.145198 7.3608 0.436083 7.90265L6.63514 13.682C6.92317 13.9498 7.3885 13.9532 7.68025 13.6889C7.97871 13.419 7.98156 12.9743 7.68832 12.7003L2.01463 7.41214C1.72361 7.14087 1.72361 6.70169 2.01463 6.43042L7.64305 1.18394C7.93407 0.913358 7.93407 0.473492 7.64305 0.202912Z"
          fill="#4B4B4B"
        />
      </svg>
    </span>
  );
  const NextIcon = () => (
    <span className="custom-arrow">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.87514 13.6821C7.16615 13.9527 7.63734 13.9527 7.92762 13.6821L14.0821 7.9451C14.6634 7.40325 14.6634 6.52421 14.0821 5.98236L7.88305 0.203041C7.59501 -0.0647635 7.12969 -0.0682337 6.83794 0.196103C6.53948 0.465989 6.53662 0.910708 6.82987 1.18476L12.5036 6.47287C12.7946 6.74414 12.7946 7.18332 12.5036 7.45459L6.87514 12.7011C6.58412 12.9717 6.58412 13.4115 6.87514 13.6821Z"
          fill="#4B4B4B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.263077 13.7976C0.554092 14.0682 1.02528 14.0682 1.31556 13.7976L7.47004 8.06058C8.05133 7.51872 8.05133 6.63969 7.47004 6.09784L1.27099 0.31852C0.982954 0.050715 0.517632 0.0472448 0.225877 0.311581C-0.0725831 0.581468 -0.0754368 1.02619 0.217806 1.30024L5.8915 6.58834C6.18252 6.85962 6.18252 7.2988 5.8915 7.57007L0.263077 12.8165C-0.0279371 13.0871 -0.0279371 13.527 0.263077 13.7976Z"
          fill="#4B4B4B"
        />
      </svg>
    </span>
  );
  const NextIcon2 = () => (
    <span className="custom-arrow">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.263199 13.6821C0.554214 13.9527 1.0254 13.9527 1.31568 13.6821L7.47017 7.9451C8.05145 7.40325 8.05145 6.52421 7.47017 5.98236L1.27111 0.203041C0.983076 -0.0647635 0.517754 -0.0682337 0.225999 0.196103C-0.072461 0.465989 -0.0753147 0.910708 0.217928 1.18476L5.89162 6.47287C6.18264 6.74414 6.18264 7.18332 5.89162 7.45459L0.263199 12.7011C-0.027815 12.9717 -0.027815 13.4115 0.263199 13.6821Z"
          fill="#4B4B4B"
        />
      </svg>
    </span>
  );
  const [datesRange, setDatesRange] = useState([new Date(), new Date()]);
  const handleSelect = (date) => {
    setDatesRange(date);
    console.log(datesRange);
  };
  const handleSchedule = async () => {
    const payload = {
      date_from: moment(datesRange[0]).format("DD-MM-YYYY"),
      date_to: moment(datesRange[1]).format("DD-MM-YYYY"),
      id: props.data.row.user.id,
      pickup_size: props.data.row.pickup_size,
    };
    // console.log(payload);
    const response = await grantPickup(payload);
    console.log(response);
    // showOptions(false);
    if (response.changeStatus === true) {
      const payload2 = {
        pickup_status: "in_progress",
        private_key: props.data.row.user.private_key,
        pickup_id: props.data.row.id,
      };
      // console.log(props);
      const response2 = await updatePickup(payload2);
      console.log(response2);
    }
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        className="MuiDataGrid-cellContent"
        onClick={handleClick}
      >
        {new Date(props.data.row.date).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </div>
      {showOptions && (
        <>
          <div className="admin-calendar">
            <p>Schedule Date for {props?.data?.row?.user.name} </p>
            <Calendar
              prevLabel={<PrevIcon2 />}
              nextLabel={<NextIcon2 />}
              prev2Label={<PrevIcon />}
              next2Label={<NextIcon />}
              minDate={new Date()}
              selectRange={true}
              onChange={handleSelect}
              value={datesRange}
            />
            <button
              onClick={() => {
                handleSchedule();
              }}
            >
              Schedule
            </button>
          </div>
        </>
      )}
    </>
  );
}
const PendingPickups = () => {
  const columns = [
    {
      field: "user.name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => {
        const name = params.row.user.name;
        const email = params.row.user.email;
        return (
          <div className="name-field">
            <div className="name-field-h1">{name}</div>
            <div className="name-field-p">{email}</div>
          </div>
        );
      },
    },
    {
      field: "date_range",
      headerName: "Date Range",
      width: 340,
      renderCell: (params) => <DateCell data={params} />,
    },
    { field: "pickup_size", headerName: "Pickup Size", width: 200 },
    {
      field: "pickup_status",
      headerName: "Pickup Status",
      width: 260,
      renderCell: (params) => (
        <StatusCell
          id={params.row.id}
          value={params.value}
          private_key={params?.row?.user?.private_key}
          data={params}
        />
      ),
    },

    {
      field: "user.zip",
      headerName: "Address",
      width: 240,
      valueGetter: (params) => `${params.row.user.address}`,
    },
    {
      field: "user.phone",
      headerName: "Phone",
      width: 300,
      valueGetter: (params) => `${params.row.user.phone}`,
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleRowSelection = (selection) => {
    setSelectedRowIds(selection.selectionModel);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllPickups();
        const pendingPickups = response.filter(
          (pickup) => pickup.pickup_status === "pending" || pickup.pickup_status === "confirmed"
        );
        setData(pendingPickups);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="recall-dashboard">
      <div className="recall-new-dashboard-grid">
        <div className="get-all-user-table">
          <div
            className="datagrid-table"
            style={{ height: "800px", width: "100%", margin: "2rem 0" }}
          >
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              // autoHeight
              getRowHeight={(params) => 100}
              checkboxSelection
              columnHeaderHeight={80}
              onSelectionModelChange={handleRowSelection}
              selectionModel={selectedRowIds}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingPickups;
