import { errorToast } from "../../utils/toast";
import apiCall from "../apiCall";

export const getAllStaff = async () => {
  try {
    const data = await apiCall("/getStaff", "GET");
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const updateStaff = async (formData) => {
  try {
    const data = await apiCall("/updateStaff", "PUT", formData);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};
