import React from "react";
import { useNavigate } from "react-router-dom";
import Brand from "../../assets/Brand";
import { login } from "../../services/Auth";
const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: e.target[0].value,
      password: e.target[1].value,
    };
    try {
      const response = await login(navigate, payload);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="admin-login-page">
      <img src={Brand.Logo} alt="" />
      <h1>Welcome to Recall Admin</h1>
      <form onSubmit={handleSubmit} action="">
        <div className="input-authentication">
          <input type="email" required placeholder="Admin email" />
        </div>
        <div className="input-authentication">
          <input type="password" required placeholder="Admin password" />
        </div>
        <div className="admin-btn">
          <button type="submit">Log In</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
