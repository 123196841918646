import Home from "./Home.svg";
import WhiteHome from "./WhiteHome.svg";
import Profile from "./Profile.svg";
import WhiteProfile from "./WhiteProfile.svg";
import Store from "./Store.svg";
import WhiteStore from "./WhiteStore.svg";
import Transaction from "./Transaction.svg";
import WhiteTransaction from "./WhiteTransaction.svg";
import Dates from "./Dates.svg";
import WhiteDates from "./WhiteDates.svg";
import Pickup from "./Pickup.svg";
import WhitePickup from "./WhitePickup.svg";
import Logout from "./Logout.svg";
import WhiteStaff from "./WhiteStaff.svg";
import Staff from "./Staff.svg";
import AllMail from "./AllMail.svg";
import AllPickup from "./AllPickup.svg";
import AllUsers from "./AllUsers.svg";
import Cans from "./Cans.svg";
import Arrow from "./Arrow.svg";
import Logs from "./Logs.svg";
import Delete from "./Delete.svg";
const DashboardIcons = {
  Home,
  WhiteHome,
  Profile,
  WhiteProfile,
  Store,
  WhiteStore,
  Transaction,
  WhiteTransaction,
  Dates,
  WhiteDates,
  Pickup,
  WhitePickup,
  Logout,
  WhiteStaff,
  Staff,
  AllMail,
  AllPickup,
  AllUsers,
  Cans,
  Arrow,
  Logs,
  Delete
};

export default DashboardIcons;
