import React, { useState, useEffect } from "react";
import { getAllStaff, updateStaff } from "../../services/Staff";
import { getAreas } from "../../services/Pickups";
const AllotStaff = () => {
  const [data, setData] = useState([]);
  const [areas, setareas] = useState();
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState();

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const res = await getAllStaff(); // Use await to get the actual data from the promise
        console.log(res.staff);
        setData(res?.staff);
      } catch (error) {
        console.error("Error fetching staff:", error);
      }
    };
    const fetchAreas = async () => {
      const res = await getAreas();
      console.log(res);
      setareas(res?.areas);
    };
    fetchAreas();

    fetchStaff();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      staff_id: parseInt(selectedStaff),
      area_allotted_ids: selectedAreas,
    };
    // console.log(payload);
    const res = await updateStaff(payload);
    console.log(res);
  };
  const toggleAreaSelection = (areaId) => {
    setSelectedAreas((prevSelectedAreas) => {
      // Check if the areaId is already in the selectedAreas state
      const isSelected = prevSelectedAreas.includes(areaId);
      // If it's already selected, remove it from the selectedAreas state
      if (isSelected) {
        return prevSelectedAreas.filter((id) => id !== areaId);
      } else {
        // If it's not selected, add it to the selectedAreas state
        return [...prevSelectedAreas, areaId];
      }
    });
  };
  return (
    <div className="admin-page-allow-dates">
      <div className="profile-page-user-info-cards | profile-page-user-info-cards-flex">
        <div className="profile-page-user-info-card">
          <div className="profile-page-user-info-card-header">
            <h2>Allot Staff according to zone</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="alott-dates-inputs">
              <div className="input-authentication">
                <p>Select Staff Member</p>
                <select
                  name="staff"
                  id="staff"
                  value={selectedStaff}
                  onChange={(e) => setSelectedStaff(e.target.value)}
                >
                  <option value="">Select</option>
                  {data?.map((staff, index) => (
                    <option key={index} value={staff.id}>
                      {staff.id}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-authentication">
                <p>Select Zone </p>
                <div className="selecct-area-staff">
                  {areas?.map((area, index) => (
                    <div
                      onClick={() => toggleAreaSelection(area.id)}
                      key={index}
                      className={`staff-area ${
                        selectedAreas.includes(area.id) ? "active" : ""
                      }`}
                    >
                      {area.area}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="edit-button | allot-dates-button">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
        <div className="profile-page-zone-cards |  profile-page-user-info-card">
          <div className="profile-page-user-info-card-header">
            <h2>Alloted Staff Members</h2>
          </div>
          <div className="staff-alooted-cards">
            {data.map((staff, index) => {
              const processedAreaIds = new Set();
              return (
                <div className="staff-alooted-card" key={index}>
                  <h2>{staff.name}</h2>
                  <div className="staff-alloted-card-areas">
                    {staff.area_allotted.map((area, index) => {
                      const areaId = area[0]; // Assuming "area[0]" contains the area ID
                      if (!processedAreaIds.has(areaId)) {
                        processedAreaIds.add(areaId);
                        return (
                          <div className="staff-alloted-card-area" key={index}>
                            <p> {area[1]} </p>
                            {/* Assuming "area[1]" contains the area name */}
                          </div>
                        );
                      }
                      return null; // Render nothing for already processed area IDs
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllotStaff;
