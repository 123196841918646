import { errorToast } from "../../utils/toast";
import apiCall from "../apiCall";

export const getAllPickups = async () => {
  const formData = {
    token: JSON.parse(localStorage.getItem("recallAdmin"))?.admin_token,
  };
  try {
    const data = await apiCall("/getAllPickups", "POST", formData);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const grantPickup = async (formData) => {
  const admin_token = JSON.parse(localStorage.getItem("recallAdmin"));
  const payload = {
    token: admin_token.admin_token,
    user_id: formData.id,
    date_from: formData.date_from,
    date_to: formData.date_to,
    pickup_size: formData.pickup_size,
  };
  try {
    const data = await apiCall("/grantPickup", "POST", payload);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const incrementUserPoints = async (formData) => {
  try {
    const data = await apiCall("/IncrementUserStats", "POST", formData);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const updatePickup = async (formData) => {
  try {
    const data = await apiCall("/updatePickup", "PUT", formData);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const getAreas = async () => {
  try {
    const data = await apiCall("/getAllAreas", "GET");
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const addarea = async (formData) => {
  const token = JSON.parse(localStorage.getItem("recallAdmin"))?.admin_token;
  const payload = {
    admin_token: token,
    area_name: formData.zone,
    date: formData.date_from,
  };
  try {
    const data = await apiCall("/addArea", "POST", payload);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};
