import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
const SideBarWidth = () => {
  const location = useLocation();
  const [show, setshow] = useState(true);
  useEffect(() => {
    if ( 
      location.pathname === "/login" 
    ) {
      setshow(false);
    } else {
      setshow(true);
    }
  }, [location]);
  return <>{show ? <div className="sidebar-width"></div> : null}</>;
};

export default SideBarWidth;
