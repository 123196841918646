import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Done from "../assets/done.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width: "500px",
  oveflow: "hidden",
  height: "500px",
  p: 4.4,
};

const UserRecentActivity = ({ open, handleClose, data }) => {
  console.log("child", data);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="user-recent-activity">
          {data?.map((item, index) => {
            return (
              <>
                <div className="user-recent-activity-item">{item.log}</div>
              </>
            );
          })}
        </div>
      </Box>
    </Modal>
  );
};

export default UserRecentActivity;
