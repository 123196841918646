import React, { useState, useEffect } from "react";
import DashboardIcons from "../../assets/DashboardIcons";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Brand from "../../assets/Brand";
import LogoutModal from "../../modals/AuthenticationModals/LogoutModal";
const SideBar = () => {
  const location = useLocation();
  const [show, setshow] = useState(true);
  const [open, setOpen] = useState(false);
  const [childShow, setChildShow] = useState({});

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (location.pathname === "/login") {
      setshow(false);
    } else {
      setshow(true);
    }
  }, [location]);
  const navigate = useNavigate();

  const sidebarData = {
    admin: [
      {
        name: "Admin Dashboard",
        icon: DashboardIcons.Home,
        whiteicon: DashboardIcons.WhiteHome,
        nonvistible: false,
        path: "/",
      },
      {
        name: "Pickups",
        icon: DashboardIcons.Pickup,
        whiteicon: DashboardIcons.WhitePickup,
        nonvistible: true,
        children: [
          {
            name: "All Pickups",
            icon: DashboardIcons.Pickup,
            whiteicon: DashboardIcons.WhitePickup,
            nonvistible: false,
            path: "/all-pickups",
          },
          {
            name: "Pending Pickups",
            icon: DashboardIcons.Pickup,
            whiteicon: DashboardIcons.WhitePickup,
            nonvistible: false,
            path: "/pending-pickups",
          },
          {
            name: "User Activity",
            icon: DashboardIcons.Pickup,
            whiteicon: DashboardIcons.WhitePickup,
            nonvistible: false,
            path: "/user-activity",
          },
        ],
      },
      {
        name: "Allot Dates",
        icon: DashboardIcons.Dates,
        whiteicon: DashboardIcons.WhiteDates,
        nonvistible: false,
        path: "/allot-dates",
      },
      {
        name: "All Users",
        icon: DashboardIcons.Profile,
        whiteicon: DashboardIcons.WhiteProfile,
        nonvistible: false,
        path: "/all-users",
      },
      {
        name: "Staff",
        icon: DashboardIcons.Staff,
        whiteicon: DashboardIcons.WhiteStaff,
        nonvistible: true,
        children: [
          {
            name: "Create Staff",
            icon: DashboardIcons.Profile,
            whiteicon: DashboardIcons.WhiteProfile,
            nonvistible: false,
            path: "/create-staff",
          },
          {
            name: "Allot Staff",
            icon: DashboardIcons.Profile,
            whiteicon: DashboardIcons.WhiteProfile,
            nonvistible: false,
            path: "/allot-staff",
          },
        ],
      },
      {
        name: "Ecommerce",
        icon: DashboardIcons.Store,
        whiteicon: DashboardIcons.WhiteStore,
        nonvistible: true,
        children: [
          {
            name: "All Products",
            icon: DashboardIcons.Store,
            whiteicon: DashboardIcons.WhiteStore,
            nonvistible: false,
            path: "/all-products",
          },
          {
            name: "Add Product",
            icon: DashboardIcons.Store,
            whiteicon: DashboardIcons.WhiteStore,
            nonvistible: false,
            path: "/add-product",
          },
          {
            name: "Edit Product",
            icon: DashboardIcons.Store,
            whiteicon: DashboardIcons.WhiteStore,
            nonvistible: false,
            path: "/edit-product",
          },
          {
            name: "Delete Product",
            icon: DashboardIcons.Store,
            whiteicon: DashboardIcons.WhiteStore,
            nonvistible: false,
            path: "/delete-product",
          },
          {
            name: "All Orders",
            icon: DashboardIcons.Store,
            whiteicon: DashboardIcons.WhiteStore,
            nonvistible: false,
            path: "/all-orders",
          },
          {
            name: "All Returns",
            icon: DashboardIcons.Store,
            whiteicon: DashboardIcons.WhiteStore,
            nonvistible: false,
            path: "/all-returns",
          },

          {
            name: "All Transactions",
            icon: DashboardIcons.Transaction,
            whiteicon: DashboardIcons.WhiteTransaction,
            nonvistible: false,
            path: "/all-transactions",
          },
        ],
      },
    ],
  };

  return (
    <>
      <LogoutModal open={open} handleClose={handleClose} />
      {show && (
        <div className="sidebar">
          <div className="sidebar-logo">
            <img src={Brand.Logo} alt="" />
          </div>

          <>
            <div className="sidebar-navigation | sidebar-data">
              <p>Navigation</p>
              <div className="sidebar-navigation-list">
                {sidebarData.admin.map((item, index) => {
                  return (
                    <div className="sidebar-navigation-item-wrap">
                      <div
                        onMouseEnter={(e) => {
                          e.currentTarget.classList.add(
                            "sidebar-navigation-item-hovered"
                          );
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.classList.remove(
                            "sidebar-navigation-item-hovered"
                          );
                        }}
                        onClick={() => {
                          if (item.nonvistible && !item.children) {
                            setOpen(true);
                          } else if (item.nonvistible && item.children) {
                            setChildShow((prevChildShow) => ({
                              ...prevChildShow,
                              [index]: !prevChildShow[index],
                            }));
                          } else {
                            navigate(item.path);
                          }
                        }}
                        className={`sidebar-navigation-item-flex ${
                          (location.pathname === item.path &&
                            "sidebar-navigation-item-flex-active") ||
                          ""
                        }`}
                      >
                        <div className="sidebar-navigation-item-img">
                          <img
                            src={
                              location.pathname === item.path
                                ? item.whiteicon
                                : item.icon
                            }
                            alt=""
                          />
                        </div>
                        <div className="sidebar-navigation-item-text">
                          {item.name}
                        </div>
                      </div>
                      {childShow[index] && item.children && (
                        <div className="sidebar-navigation-sublist">
                          {item.children.map((childItem, childIndex) => (
                            <div
                              className={`sidebar-navigation-subitem ${
                                (location.pathname === childItem.path &&
                                  "sidebar-navigation-item-active") ||
                                ""
                              }`}
                              onMouseEnter={(e) => {
                                e.currentTarget.classList.add(
                                  "sidebar-navigation-item-hovered"
                                );
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.classList.remove(
                                  "sidebar-navigation-item-hovered"
                                );
                              }}
                              onClick={() => {
                                if (childItem.nonvistible) {
                                  setOpen(true);
                                } else {
                                  navigate(childItem.path);
                                }
                              }}
                            >
                              <div className="sidebar-navigation-item-img"></div>
                              <div className="sidebar-navigation-item-text">
                                {childItem.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default SideBar;
