import { errorToast } from "../../utils/toast";
import apiCall from "../apiCall";

export const getAdminStats = async () => {
  const admin_token = JSON.parse(localStorage.getItem("recallAdmin"));
  const formData = {
    token: admin_token.admin_token,
  };
  try {
    const data = await apiCall("/getAdminStats", "POST", formData);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const getAllUser = async () => {
  const payload = {
    token: JSON.parse(localStorage.getItem("recallAdmin"))?.admin_token,
  };
  try {
    const data = await apiCall("/getAllUsers", "POST", payload);
    return data;
  } catch (error) {
    errorToast(error.message);
  }
};

export const getUserlogs = async () => {
  try {
    const payload = {
      private_key: "VV58SB17600I7ZL",
    };

    return apiCall("/recallHistory", "POST", payload);
  } catch (error) {
    throw new Error(`Getting Admin Stats failed: ${error.message}`);
  }
};
